





























































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import { NumberPerStudentTypeReportedDto } from '@/models/planning/NumberPerStudentTypeReportedDto';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { StudentTypeDto } from '@/models/planning/StudentTypeDto';
import { EnumDto } from '@/models/EnumDto';

export default Vue.extend({
  name: 'classSubjectStudentType-view',
  data: () => ({
    form: {} as NumberPerStudentTypeReportedDto,

  }),
  async mounted() {
    await Promise.all([
      this.loadClassSubjects(),
      this.loadStudentTypes(),
      this.loadIncludeInAnnualManhours()
    ]);

    this.assignCriteria([
      this.criteriaRequired('relStudentType', () => this.form.relStudentType),
      this.criteriaRequired('includeInAnnualManhours', () => this.form.includeInAnnualManhours),
      this.criteriaRequired('sharePercentageForClassAverage', () => this.form.sharePercentageForClassAverage)
    ]);
  },
  methods: {
    ...mapActions('classSubjectStore', ['loadClassSubjects']),
    ...mapActions('studentTypeStore', ['loadStudentTypes']),
    ...mapActions('enumStore', ['loadIncludeInAnnualManhours']),
    ...mapActions('classSubjectStudentTypeStore', ['createNumberPerStudentTypeReported', 'loadNumberPerStudentTypeReporteds']),
    ...mapActions('validationStore', ['assignCriteria', 'validate']),
    close() {
      if (this.isLoading) return;
      this.$emit('close');
    },
    async create() {
      try {
        if (await this.validate()) {
          await this.createNumberPerStudentTypeReported(this.form);
          await this.loadNumberPerStudentTypeReporteds(this.form.relClassSubject);
          this.close();
        }
        else {
          this.$buefy.toast.open({
            message: 'Valideringsfejl - check værdierne i de markerede felter.',
            type: 'is-danger'
          });
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved tilknytning af deltagertype.',
          type: 'is-danger'
        });
      }
    },

  },
  computed: {
    ...mapGetters('classSubjectStore', ['classSubjects', 'classSubjectApiState']),
    ...mapGetters('studentTypeStore', ['studentTypeApiState', 'studentTypes']),
    ...mapGetters('enumStore', ['includeInAnnualManhoursApiState', 'includeInAnnualManhours']),
    ...mapGetters('classSubjectStudentTypeStore', ['numberPerStudentTypeReported', 'numberPerStudentTypeReporteds']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage', 'criteriaRequired', 'criteriaLength', 'criteriaValue']),
    isLoading() {
      return this.isLoadingClassSubjects
        || this.isLoadingStudentTypes
        || this.isLoadingIncludeInAnnualManhours;
    },
    isLoadingClassSubjects() {
      return this.classSubjectApiState === APISTATE.LOADING;
    },
    isLoadingStudentTypes() {
      return this.studentTypeApiState === APISTATE.LOADING;
    },
    isLoadingIncludeInAnnualManhours() {
      return this.includeInAnnualManhoursApiState === APISTATE.LOADING;
    },
    availableStudentTypes() {
      const used = this.numberPerStudentTypeReporteds.map(q => q.relStudentType);
      const available = this.studentTypes.filter(q => !used.includes(q.id));
      return available;
    },
    formRelClassSubject: {
      get() {
        return this.classSubjects.find(q => q.id === this.form.relClassSubject);
      },
      set(value:ClassSubjectDto) {
        this.$set(this.form, 'relClassSubject', value.id);
      }
    },
    formRelStudentType: {
      get() {
        return this.studentTypes.find(q => q.id === this.form.relStudentType);
      },
      set(value:StudentTypeDto) {
        this.$set(this.form, 'relStudentType', value.id);
      }
    },
    formIncludeInAnnualManhours: {
      get() {
        return this.includeInAnnualManhours.find(q => q.value === this.form.includeInAnnualManhours);
      },
      set(value:EnumDto) {
        this.$set(this.form, 'includeInAnnualManhours', value.value);
      }
    },
    formSharePercentageForClassAverage: {
      get() {
        return Math.round((this.form?.sharePercentageForClassAverage ?? 0) * 100);
      },
      set(value:number) {
        this.$set(this.form, 'sharePercentageForClassAverage', value / 100);
      }
    },
    calcCalculatedWeight() {
      return 1.0;
    }
  },
  watch: {
    numberPerStudentTypeReported: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.form = {...newValue};
      }
    }
  }
})
