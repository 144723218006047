





























































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';
import ClassSubjectStudentType from './ClassSubjectStudentTypes/ClassSubjectStudentType.vue';

export default Vue.extend({
  name: 'classSubjectStudentTypes',
  components: { ClassSubjectStudentType },
  props: {
    classSubjectId: {
      type: String,
      required: false
    }
  },
  data: () => ({
    deleting: undefined,

  }),
  async mounted() {
    await Promise.all([
      this.loadClassSubject(this.classSubjectId),
      this.loadNumberPerStudentTypeReporteds(this.classSubjectId)
    ]);

  },
  methods: {
    ...mapActions('classSubjectStore', ['loadClassSubject']),
    ...mapActions('classSubjectStudentTypeStore', [
      'loadNumberPerStudentTypeReporteds',
      'removeNumberPerStudentTypeReported',
      'prepareNewNumberPerStudentTypeReported',
      'clearNumberPerStudentTypeReported'
    ]),
    addStudentType() {
      this.prepareNewNumberPerStudentTypeReported(this.classSubjectId);
    },
    async remove(controls) {
      try {
        await this.removeNumberPerStudentTypeReported({
          relClassSubject: this.classSubjectId,
          relStudentType: this.deleting.id
        });
        controls.close();
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af deltagertype',
          type: 'is-danger'
        });
      }
    },
    close() {
      this.clearNumberPerStudentTypeReported();
    }

  },
  computed: {
    ...mapGetters('classSubjectStore', ['classSubject', 'classSubjectApiState']),
    ...mapGetters('classSubjectStudentTypeStore', ['classSubjectStudentTypeApiState', 'numberPerStudentTypeReporteds', 'numberPerStudentTypeReported']),
    editing: {
      get() {
        return this.numberPerStudentTypeReported !== undefined;
      },
      set(value) {
        if (!value) {
          this.close();
        }
      }
    },
    isLoading() {
      return this.isLoadingClassSubjects
        || this.isLoadingClassSubjectStudentTypes;
    },
    isLoadingClassSubjects() {
      return this.classSubjectApiState === APISTATE.LOADING;
    },
    isLoadingClassSubjectStudentTypes() {
      return this.classSubjectStudentTypeApiState === APISTATE.LOADING;
    },
    classId() {
      return this.classSubject?.classId ?? 'n/a';
    },
    tabs() {
      return [
        { title: 'Stamdata', to: `/classSubject/${this.classSubjectId}`},
        { title: 'Deltager typer', to: `/classSubject/${this.classSubjectId}/studentTypes`}
      ];
    }
  }
})
